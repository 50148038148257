import Result from './componets/Result';
import {Routes, Route, Navigate} from 'react-router-dom';
import Home from './componets/Home';
import {YMInitializer} from "react-yandex-metrika";
import React from "react";


class App extends React.Component {
    render() {
        let metricaId: number[] = [
            parseInt(process.env.REACT_APP_YANDEX_METRICA_ID || "0")
        ];

        return (
            <div>
                <YMInitializer accounts={metricaId}
                               options={{clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true}}/>
                <Routes>
                    <Route path="*" element={<Navigate replace to={'/'}/>}/>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/result" element={<Result/>}/>
                </Routes>
            </div>
        );
    }
}

export default App;
