export default function Header() {
	return (
		<div>
			<header className="header">
				<div className="header__logo" />
				{/*<div className="header__language-box">/!* <button className="header__kz" /> *!/</div>*/}
				<div className="call_center">Колл центр <a href="tel:+77475469069">+7 7475 469069</a><br /> (с 10 до 18 по Астане)</div>
			</header>
		</div>
	);
}
