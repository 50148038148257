export default function AgreementKZ() {
    return (
        <div>
            <p className="agreement__title">Условия использования и оплаты</p>
            <p className="agreement__text">
                Политика проведения платежей. Оплата банковской картой онлайн Наш сайт подключен к
                интернет-эквайрингу, и Вы можете оплатить заказ банковской картой Visa или Mastercard прямо
                на сайте. После подтверждения выбранного заказа откроется защищенное окно с платежной
                страницей платёжного сервиса Robokassa, где Вам необходимо ввести данные Вашей банковской
                карты и адрес электронной почты для квитанции или фискального чека. Мы используем протокол
                3D Secure для подтверждения оплаты. Если Ваш Банк поддерживает данный протокол, Вы будете
                перенаправлены на сервер банка для дополнительной идентификации c помощью SMS кода.
                Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам
                банковскую карту. В поля на платежной странице требуется ввести номер карты, адрес
                электронной почты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2
                для MasterCard). Все необходимые данные отображены на поверхности банковской карты. CVV2/
                CVC2 — это трёхзначный код безопасности, находящийся на оборотной стороне карты. Далее в том
                же окне откроется страница Вашего банка-эмитента для ввода 3-D Secure кода. В случае, если у
                вас не настроен статичный 3-D Secure, он будет отправлен на ваш номер телефона посредством
                SMS. Если 3-D Secure код к Вам не пришел, то следует обратится в ваш банк-эмитент. 3-D
                Secure — это самая современная технология обеспечения безопасности платежей по картам в сети
                интернет. Позволяет однозначно идентифицировать подлинность держателя карты, осуществляющего
                операцию, и максимально снизить риск мошеннических операций по карте. Случаи отказа в
                совершении платежа: В случае, если ваш платёж не прошёл или операция была отменена,
                проверьте: —правильно ли были введены реквизиты? Обратите внимание на срок действия вашей
                карты и номер; —достаточно ли средств на вашей карте? Подробнее о наличии средств на
                платежной карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту; —открыта
                ли возможность проведения оплат в интернете? Подробнее о возможностях вашей карты карты Вы
                можете узнать, обратившись в банк-эмитент; —хватает ли вам ежедневного лимита на платежи в
                интернете? Подробнее о лимитах вашей карты карты Вы можете узнать, обратившись в
                банк-эмитент. По вопросам непрошедшей оплаты, пожалуйста обратитесь в службу поддержки
                банка-эмитента, выпустившего вашу банковскую карту.
            </p>
            <p className="agreement__title">Гарантии безопасности</p>
            <p className="agreement__text">
                Платёжный сервис Robokassa защищает и обрабатывает данные Вашей банковской карты по
                стандарту безопасности PCI DSS. Передача информации в платежный шлюз происходит с
                применением технологии шифрования SSL. Дальнейшая передача информации происходит по закрытым
                банковским сетям, имеющим наивысший уровень надежности. Robokassa не передает данные Вашей
                карты интернет магазину или третьим лицам. Для дополнительной аутентификации держателя карты
                используется протокол 3D Secure. В случае, если у Вас есть вопросы по совершенному платежу,
                Вы можете обратиться в службу поддержки клиентов по электронной почте support@robokassa.kz
            </p>
            <p className="agreement__title">Безопасность онлайн платежей</p>
            <p className="agreement__text">
                Предоставляемая Вами персональная информация (e-mail, номер банковской карты) является
                конфиденциальной и не подлежит разглашению. Данные Вашей банковской карты передаются только
                в зашифрованном виде и не сохраняются на нашем сервере. Безопасность обработки
                Интернет-платежей гарантирует платёжный сервис Robokassa. Все операции с платежными картами
                происходят в соответствии с требованиями VISA International, MasterCard Worldwide и других
                платежных систем. При передаче информации используется специальные технологии безопасности
                карточных онлайн-платежей, обработка данных ведется на безопасном высокотехнологичном
                сервере платёжного сервиса. Оплата платежными картами безопасна, потому что: · Система
                авторизации гарантирует покупателю, что платежные реквизиты его платежной карты (номер, срок
                действия, CVV2/CVC2) не попадут в руки мошенников, так как эти данные не хранятся на сервере
                в зашифрованном виде и не могут быть похищены. · Покупатель вводит свои платежные данные
                непосредственно в системе авторизации Robokassa, а не на сайте интернет-магазина,
                следовательно, платежные реквизиты карточки покупателя не будут доступны третьим лицам.
            </p>
            <p className="agreement__title">Договор публичной оферты</p>
            <p className="agreement__text">
                (Публичная оферта – это содержащее все существенные условия договора предложение, из
                которого усматривается воля лица, делающего предложение заключить договор на указанных в
                публичной оферте условиях с любым лицом, которое отзовется на это предложение в соответствии
                с п. 5 ст. 395 Гражданского кодекса Республики Казахстан) Текст Договора является
                предложением (публичной офертой) на использование онлайн сервиса: «immuniguide.com» (далее –
                Сайт), доступ к которому предоставляет возможность получения услуг и пользования
                информационными ресурсами Администратора сайта «ТОО «НЦКИМ»» (Юр. Лицо)(далее –
                Администратор). Оплата услуг представленных на сайте Администратора физическим /юридическим
                лицом (далее – Пользователь) являться акцептом данной публичной оферты, что равносильно
                заключению договора (далее – Договор) на условиях, изложенных в нем. В случае, если
                Пользователь не согласен с текстом представленного Договора, Администратор предлагает
                отказаться от использования предоставляемых услуг.
            </p>
            <p className="agreement__title agreement__points">1. 1. Основные положения</p>
            <p className="agreement__text">
                1.2. Пользователь и Администратор заключили настоящий договор (далее – Договор) на получение
                услуг представленных Администратором, в соответствии с действующим законодательством
                Республики Казахстан. 2.2. Термины, используемые в тексте Договора: · «Оферта» — публичное
                предложение на использование онлайн сервиса; · «Акцепт» — безоговорочное принятие
                Пользователем условий договора в полном объеме; · «Администратор» — сервис-провайдер,
                являющийся собственником сайта; · «Пользователь» — любое физическое/юридическое лицо которое
                принимает условия договора и пользуется услугами представленными на сайте Администратора; ·
                «Услуги» — «Проверка иммунитета по авторский технологии ТОО «НЦКИМ»; · «Сайт» — совокупность
                программных средств, обеспечивающих публикацию для всеобщего обозрения информации и данных,
                объединенных общим целевым назначением, посредством технических средств. Сайт доступен
                Пользователям по уникальному электронному адресу или его буквенному обозначению. Под Сайтом
                в Соглашении понимается Сайт Администрации, расположенный в сети Интернет по
                адресу«immuniguide.com»
            </p>
            <p className="agreement__title agreement__points">2. Предмет оферты</p>
            <p className="agreement__text">
                2.1. Администратор предоставляет услуги по «Проверке иммунитета по авторский технологии ТОО
                «НЦКИМ»» на Сайте. 2.2. Администратор обязуется оказывать техническое обслуживание и
                поддержку Сайта. 2.3. Действующая редакция Договора находится на Сайте в публичном доступе
                по адресу: «immuniguide.com».
            </p>
            <p className="agreement__title agreement__points">3. Использование онлайн сервиса</p>
            <p className="agreement__text">
                3.1. Для получения услуги Администратора Пользователь по своему желанию выбирает тарифный
                план, проводит регистрацию путем предоставления персональных данных и производит оплату.
                3.2. Оплата Пользователя означает безоговорочное и полное согласие с условиями Договора.
                День оплаты Пользователем Услуг считается днем заключения Договора на срок указанный в
                пакете услуг.
            </p>
            <p className="agreement__title agreement__points">
                4. Регистрация на сайте, конфиденциальность и защита персональных данных
            </p>
            <p className="agreement__text">
                4.1. Персональные данные содержат в себе следующую информацию: · Фамилия, имя, отчество
                Пользователя; · Возвраст; · Пол; · Данные общего анализа крови; · Адрес электронной почты
                (E-mail); · Пароль и логин для входа в личный кабинет; · Статус Пользователя
                (физическое/юридическое лицо); 4.2. При необходимости Пользователь имеет право редактировать
                внесенные данные о себе в личном кабинете. 4.3. Администратор обязуется не разглашать
                полученную от Пользователя информацию. Не считается нарушением обязательств разглашение
                информации в соответствии с обоснованными требованиями, согласно действующему
                законодательству Республики Казахстан. 4.4. Пользователь несет ответственность за все
                действия и последствия использования личного кабинета, за добровольную передачу доступа к
                своему личному кабинету третьим лицам, а также за несанкционированный доступ. Все
                перечисленные действия будут считаться произведенными самим Пользователем.
            </p>
            <p className="agreement__title agreement__points">5. Права и обязанности сторон</p>
            <p className="agreement__text">
                5.1. Администратор: · Обязуется оказывать техническую поддержку и предоставлять полную
                информацию в рамках пакетных Услуг. · Обязуется не разглашать персональные данные
                Пользователя; · Не несет ответственность в связи с информацией, предоставленной
                Пользователем. · Имеет право в одностороннем порядке изменять условия предоставления Услуг и
                вносит изменения в редакцию Договора. 5.2. Пользователь: · Несет полную ответственность за
                правильность информации, введенной при регистрации на Сайте Администратора, а также за
                несвоевременное представление изменений в регистрационные данные; · Несет личную
                ответственность за любые действия, совершенные с использованием своего личного кабинета, а
                также за любые последствия, которые могло повлечь или повлекло такое использование третьими
                лицами при ненадлежащем хранении Пользователем логина и пароля. · Имеет право пользоваться
                услугами Сайта в своих интересах, непосредственно не вмешиваясь в его работу.
            </p>
            <p className="agreement__title agreement__points">6. Порядок оплаты</p>
            <p className="agreement__text">
                {' '}
                6.1. Стоимость,
                оплачиваемая Пользователем с применением промокода на скидку определяются на соответствующем
                этапе оформления заказа. Оформляя заказ, Пользователь соглашается, что извещен и согласен с
                оплатой стоимости Услуг. 6.2. Оплата производится на счет Администратора банковскими картами
                или иными безналичными способами после проведения регистрации по следующим тарифным планам
                (пакетам Услуг): 6.3. Услуги предоставляются при условии 100% предоплаты, тестовые периоды
                не предоставляются. 6.4. Администратор самостоятельно контролирует срок истечения
                предоставления Услуг. 6.5. За правильность платежей ответственность лежит на Пользователе.
            </p>
            <p className="agreement__title agreement__points">
                7. Ответственность сторон, разрешение споров
            </p>
            <p className="agreement__text">
                {' '}
                7.1. За неисполнение или ненадлежащее исполнение своих обязательств Стороны несут
                ответственность в соответствии с действующим законодательством Республики Казахстан. 7.2.
                Все споры, возникающие между Сторонами при исполнении настоящей Оферты, разрешаются путем
                мирных переговоров, а в случае недостижения согласия между Сторонами, спор рассматривается в
                судебном порядке в соответствии с действующим законодательством Республики Казахстан в
                арбитражном суде по месту регистрации Администратора.
            </p>
            <p className="agreement__title agreement__points">
                8. Срок действия договора и его расторжение
            </p>
            <p className="agreement__text">
                {' '}
                8.1. Публичная оферта вступает в силу с момента акцепта Оферты и действует в течение срока
                предоставления доступа к Сайту Администратора. 8.2. Администратор имеет право блокировать
                доступ к серверу в следующих случаях: · При получении распоряжений государственных органов
                Республики Казахстан; · При нарушении авторских и смежных прав; · При мотивированном
                обращении третьих лиц при нарушении их прав; · При обнаружении запрещенной законодательством
                информации, размещенной Пользователем. 8.3. Пользователь имеет право отказаться от
                пользования предоставленными Услугами. Отказ от Услуг принимается в течение 14
                (четырнадцати) календарных дней с даты получения доступа к Сайту путем направления
                письменного заявления от Пользователя на e-mail Администратора с объяснением реальных
                мотивированных причин отказа. В случае нарушения срока в течение, которого возможен отказ,
                претензии от Пользователя не принимаются. 8.4. Возврат денежных средств производится
                Администратором за минусом денежной суммы за фактически оказанные Услуги в течение 14
                (четырнадцати) календарных дней на реквизиты Пользователя, указанные в заявлении после
                подтверждения Администратором мотивированных причин отказа от Услуг. 8.5. В случае нарушений
                условий Договора предоплаченные денежные средства не возвращаются.
            </p>
            <p className="agreement__title agreement__points">9. Прочие условия</p>
            <p className="agreement__text">
                {' '}
                9.1. Администратор имеет право в одностороннем порядке изменить условия Договора в целом или
                в его части без согласования с Пользователем. В общем доступе на Сайте публикуется
                действующая редакция. 9.2. Данная версия настоящего Договора является текущей и отменяет
                предыдущую версию. 9.3. Стороны освобождаются от ответственности за неисполнение или
                ненадлежащее исполнение условий настоящего Договора на время действий непреодолимой силы. К
                ним относятся такие события как: стихийные природные явления, военные действия, массовые
                беспорядки, а также принятие государственными органами законодательных актов, препятствующих
                выполнению условий настоящего Договора. В этом случае выполнение обязательств двумя
                Сторонами откладывается на время действия обстоятельств непреодолимой силы и их последствий.
                9.4. В рамках настоящего Договора оферты Администратор уведомляет, а Пользователь
                соглашается, что результат оказанной Услуги (сформированный электронный отчет) не является
                медицинским заключением и служит лишь методическим отчетом для дальнейшего обращения к
                своему лечащему врачу, с целью выявления и (или) подтверждения диагноза. 9.4. Во всем
                остальном, что не предусмотрено условиями настоящего Договора, Стороны руководствуются
                действующим законодательством Республики Казахстан.
            </p>
            <p className="agreement__title agreement__points">10.Реквизиты Администратора</p>
            <p className="agreement__text">
                {' '}
                ТОО «НЦКИМ» Адрес: 010000, РК, Астана, Мақсұт Нәрікбаев, 22 БИН: 200340008929 Р/c:
                KZ158562203115130557 БИК: KCJBKZKX АО «АО «БАНК ЦЕНТРКРЕДИТ» » Контактный email:
                support@immuniguide.com
            </p>
            <p className="agreement__title ">Политика конфиденциальности и обработки персональных данных</p>
            <p className="agreement__text">
                {' '}
                Настоящая политика конфиденциальности и обработки персональных данных регулирует порядок
                обработки и использования персональных и иных данных сайта ТОО «НЦКИМ» (дальше — Оператор).
                Действующая редакция настоящей Политики конфиденциальности, постоянно доступна для
                ознакомления, и размещена в сети Интернет по адресу: immuniguide.com Передавая Оператору
                персональные и иные данные посредством Сайта, Пользователь подтверждает свое согласие на
                использование указанных данных на условиях, изложенных в настоящей Политике
                конфиденциальности. Если Пользователь не согласен с условиями настоящей Политики
                конфиденциальности, он обязан прекратить использование Сайта. Безусловным акцептом настоящей
                Политики конфиденциальности является начало использования Сайта Пользователем.
            </p>
            <p className="agreement__title agreement__points ">1.Термины</p>
            <p className="agreement__text">
                {' '}
                1.1. Сайт — сайт, расположенный в сети Интернет по адресу immuniguide.com Все исключительные
                права на Сайт и его отдельные элементы (включая программное обеспечение, дизайн) принадлежат
                Оператору в полном объеме. Передача исключительных прав Пользователю не является предметом
                настоящей Политики конфиденциальности. 1.2. Пользователь — лицо использующее Сайт. 1.3.
                Законодательство — действующее законодательство Республики Казахстан. 1.4. Персональные
                данные — персональные данные Пользователя, которые Пользователь предоставляет самостоятельно
                при регистрации или в процессе использования функционала Сайта. 1.5. Данные — иные данные о
                Пользователе (не входящие в понятие Персональных данных). 1.6. Регистрация — заполнение
                Пользователем Регистрационной формы, расположенной на Сайте, путем указания необходимых
                сведений и отправки сканированных документов. 1.7. Регистрационная форма — форма,
                расположенная на Сайте, которую Пользователь должен заполнить для возможности использования
                сайта в полном объеме. 1.8. Услуга(и) — услуги, предоставляемые Оператором на основании
                соглашения.
            </p>
            <p className="agreement__title agreement__points ">2. Сбор и обработка персональных данных</p>
            <p className="agreement__text">
                {' '}
                2.1. Оператор собирает и хранит только те Персональные данные, которые необходимы для
                оказания Услуг Оператором и взаимодействия с Пользователем. 2.2. Персональные данные могут
                использоваться в следующих целях: 2.2.1 оказание Услуг Пользователю; 2.2.2 идентификация
                Пользователя; 2.2.3 взаимодействие с Пользователем; 2.2.4 направление Пользователю рекламных
                материалов, информации и запросов; 2.2.5 проведение статистических и иных исследований; 2.3.
                Оператор в том числе обрабатывает следующие данные: 2.3.1 фамилия, имя и отчество; 2.3.2
                адрес электронной почты; 2.3.3 номер телефона (в т.ч. мобильного). 2.4. Пользователю
                запрещается указывать на Сайте персональные данные третьих лиц(за исключением условия
                представления интересов этих лиц, имея документальное подтверждение третьих лиц на
                осуществление таких действий).
            </p>
            <p className="agreement__title agreement__points ">
                3. Порядок обработки персональных и иных данных
            </p>
            <p className="agreement__text">
                {' '}
                3.1. Оператор обязуется использовать Персональные данные в соответствии с Законом «О
                персональных данных» Республики Казахстан и внутренними документами Оператора. 3.2. В
                отношении Персональных данных и иных Данных Пользователя сохраняется их конфиденциальность,
                кроме случаев, когда указанные данные являются общедоступными. 3.3. Оператор имеет право
                сохранять архивную копию Персональных данных. Оператор имеет право хранить Персональные
                данные и Данные на серверах вне территории Республики Казахстан. 3.4. Оператор имеет право
                передавать Персональные данные и Данные Пользователя без согласия Пользователя следующим
                лицам: 3.4.1 государственным органам, в том числе органам дознания и следствия, и органам
                местного самоуправления по их мотивированному запросу; 3.4.2 в иных случаях, прямо
                предусмотренных действующим законодательством Республики Казахстан. 3.5. Оператор имеет
                право передавать Персональные данные и Данные третьим лицам, не указанным в п.3.4. настоящей
                Политики конфиденциальности, в следующих случаях: 3.5.1 Пользователь выразил свое согласие
                на такие действия; 3.5.2 передача необходима в рамках использования Пользователем Сайта или
                оказания Услуг Пользователю; 3.6. Оператор осуществляет автоматизированную обработку
                Персональных данных и Данных.
            </p>
            <p className="agreement__title agreement__points ">4. Защита персональных данных</p>
            <p className="agreement__text">
                {' '}
                4.1. Оператор осуществляет надлежащую защиту Персональных и иных данных в соответствии с
                Законодательством и принимает необходимые и достаточные организационные и технические меры
                для защиты Персональных данных. 4.2. Применяемые меры защиты в том числе позволяют защитить
                Персональные данные от неправомерного или случайного доступа, уничтожения, изменения,
                блокирования, копирования, распространения, а также от иных неправомерных действий с ними
                третьих лиц.
            </p>
            <p className="agreement__title agreement__points ">5. Иные положения</p>
            <p className="agreement__text">
                {' '}
                5.1. К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором,
                возникающим в связи с применением Политики конфиденциальности, подлежит применению право
                Республики Казахстан. 5.2. Все возможные споры, вытекающие из настоящего Соглашения,
                подлежат разрешению в соответствии с действующим законодательством по месту регистрации
                Оператора. Перед обращением в суд Пользователь должен соблюсти обязательный досудебный
                порядок и направить Оператору соответствующую претензию в письменном виде. Срок ответа на
                претензию составляет 30 (тридцать) рабочих дней. 5.3. Если по тем или иным причинам одно или
                несколько положений Политики конфиденциальности будут признаны недействительными или не
                имеющими юридической силы, это не оказывает влияния на действительность или применимость
                остальных положений Политики конфиденциальности. 5.4. Оператор имеет право в любой момент
                изменять Политику конфиденциальности (полностью или в части) в одностороннем порядке без
                предварительного согласования с Пользователем. Все изменения вступают в силу с момента ее
                размещения на Сайте. 5.5. Пользователь обязуется самостоятельно следить за изменениями
                Политики конфиденциальности путем ознакомления с актуальной редакцией. 5.6. Все предложения
                или вопросы по настоящей Политике конфиденциальности следует сообщать по электронной почте
                support@immuniguide.com или по телефонам: +7 (747) 546 90 69
            </p>
        </div>
    );
}