// @ts-nocheck
import React, {useEffect, useState} from 'react';
import Footer from './Footer';
import {useNavigate, useSearchParams} from 'react-router-dom';
import api from './api';

export default function Result() {
    const navigate = useNavigate();
    const [skeleton, setSkeleton] = useState(true)

    const [searchParams] = useSearchParams();
    const [activ, setActiv] = useState();
    const [grade, setGrade] = useState();
    const [gender, setGender] = useState();
    const [gradeTitle, setGradeTitle] = useState();
    const [orderId] = useState(searchParams.get('order_id'));
    const [popup, setPopup] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState();
    const [promoCode, setPromoCode] = useState(false);
    const [promoCodeValue, setPromoCodeValue] = useState('');
    const [price, setPrice] = useState('15000');
    const [paid, setPaid] = useState(false);
    const [totalPrice, setTotalPrice] = useState('3500.00');
    const [recommendations, setRecommendations] = useState([]);
    const [payment, setPayment] = useState()
    const [errorPromo, setErrorPromo] = useState()
    const [errorPromoValue, setErrorPromoValue] = useState()

    // @ts-ignore
    const handleChangePromoCodeValue = (e) => {
        setPromoCodeValue(e.target.value);
    };

    useEffect(() => {
        setPayment(searchParams.get("payment"))
        api.getById(searchParams.get('order_id')).then((res) => {
            if (res.ok) {
                setRecommendations(res.result.report.recommendations);
                setActiv(res.result.report.category);
                setGender(res.result.report.gender);
                setGrade(res.result.report.grade);
                setGradeTitle(res.result.report.grade_title);
                if (res.result.is_paid) {
                    setPaid(true);
                } else {
                    setPaid(false);
                }
                setSkeleton(false)
            } else {
                setPaid(false);
                setSkeleton(false)
            }
        });
    }, [searchParams]);

    return (
        <div>
            <div className="result">
                {skeleton ?
                    <div className="skeleton result__header_skeleton"></div> :
                    <div
                        className="result__header"
                        style={{
                            backgroundColor:
                                activ === 1
                                    ? '#6a0000'
                                    : activ === 2 || activ === 13
                                        ? '#ee6464'
                                        : activ === 3 || activ === 11
                                            ? '#e7aa78'
                                            : activ === 4 || activ === 10
                                                ? '#e3d182'
                                                : activ === 5 || activ === 9
                                                    ? '#c3df9a'
                                                    : activ === 6
                                                        ? '#aae0ab'
                                                        : activ === 7 || activ === 8
                                                            ? '#86e1c1'
                                                            : activ === 12 ? '#eb826d' : '#6a0000'
                        }}>
                        <p className="result__number">{grade}%</p>
                        <p className="result__value">
                            {gradeTitle ? gradeTitle : false}

                        </p>
                    </div>
                }
                {skeleton ?
                    <div className="skeleton rusult__scale_skeleton"></div> :
                    <div className="rusult__scale">
                        <div
                            className="result__rectangle result__rectangle_1"
                            style={{
                                height: activ === 1 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_2"
                            style={{
                                height: activ === 2 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_3"
                            style={{
                                height: activ === 3 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_4"
                            style={{
                                height: activ === 4 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_5"
                            style={{
                                height: activ === 5 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_6"
                            style={{
                                height: activ === 6 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_7"
                            style={{
                                height: activ === 7 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_7"
                            style={{
                                height: activ === 8 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_5"
                            style={{
                                height: activ === 9 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_4"
                            style={{
                                height: activ === 10 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_3"
                            style={{
                                height: activ === 11 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_12"
                            style={{
                                height: activ === 12 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_2"
                            style={{
                                height: activ === 13 ? '44px' : '28px'
                            }}
                        />
                        <div
                            className="result__rectangle result__rectangle_14"
                            style={{
                                height: activ === 14 ? '44px' : '28px'
                            }}
                        />
                    </div>
                }
                {skeleton ?
                    <div className="skeleton result__risk_skeleton"></div> :
                    <p className="result__risk">
                        Выявлены <span className="result__risk_span">5 групп рисков</span> развития заболеваний, которые
                        могут привести к истощению защитной функции организма
                    </p>
                }
                {skeleton ?
                    <div className='skeleton result__img_skeleton'></div> :
                    <div>

                        {gender === 1 ? grade < -50 || 150 < grade ? (
                            <div className="result__img result__img_man-red"/>
                        ) : false : false}
                        {gender === 1 ? (-50 <= grade && grade < 0) || (100 < grade && grade <= 150) ? (
                            <div className="result__img result__img_man-yellow"/>
                        ) : false : false}
                        {gender === 1 ? 0 <= grade && grade <= 100 ? (
                            <div className="result__img result__img_man-green"/>
                        ) : false : false}
                        {gender === 2 ? grade < -50 || 150 < grade ? (
                            <div className="result__img result__img_woman-red"/>
                        ) : false : false}
                        {gender === 2 ? (-50 <= grade && grade < 0) || (100 < grade && grade <= 150) ? (
                            <div className="result__img result__img_woman-yellow"/>
                        ) : false : false}
                        {gender === 2 ? 0 <= grade && grade <= 100 ? (
                            <div className="result__img result__img_woman-green"/>
                        ) : false : false}
                    </div>
                }
                {skeleton ?
                    <div>
                        <div className='skeleton result__box-result_skeleton'></div>
                        <div className='skeleton result__button_skeleton'></div>
                        <div className='skeleton result__button_skeleton'></div>
                    </div>
                    :
                    <div>

                        {payment === "fail" ?
                            <div>
                                <div className="result__box-result">
                                    <div className="result__box-result_img result__box-result_nok"/>
                                    <p className="result__box-result_txt"> Ошибка оплаты.</p>
                                </div>
                                <p className="result__box-result_info">
                                    Во время оплаты произошла ошибка, попробуйте еще раз.{' '}
                                </p>
                                <p className="result__box-result_info">
                                    Если ошибка повторяется, пожалуйста, обратитесь в{' '}
                                    <span className="result__box-result_span">поддержку</span>
                                </p>
                                <button
                                    className="result__button-done"
                                    onClick={() => {
                                        window.open(localStorage.getItem('paymenturl'), '_self');
                                    }}
                                >
                                    Перейти к оплате
                                </button>
                            </div> : false}
                        {paid ? (
                            <div>
                                <div className="result__box-result">
                                    <div className="result__box-result_img result__box-result_ok"/>
                                    <p className="result__box-result_txt"> Оплата произведена успешно.</p>
                                </div>
                                <p className="result__box-result_info">Спасибо за покупку.</p>
                                <p className="result__box-result_info">Копия отчета отправлена на ваш e-mail.</p>
                                <a
                                    className="result__button-done"
                                    href={process.env.REACT_APP_BASE_URL + '/api/v1/report/download?order_id=' + orderId}
                                    download
                                >
                                    <div className="result__button-done_img"/>
                                    Скачать
                                </a>
                            </div>
                        ) : (
                            <>
                                <p className="result__details">
                                    Расчет индекса иммунитета доступен бесплатно.<br/> Платный отчет о состоянии
                                    здоровья включает в себя:
                                </p>
                                <div className="result__information">
                                    {recommendations.map((item) => {
                                        return (
                                            <div className="result__points-box"
                                                 key={Math.random().toString(36).substr(2, 9)}>
                                                <div className="screen-one__ok"/>
                                                <div className="result__text-box">
                                                    <p className="result__head-txt">{item.title ? item.title : false}</p>
                                                    {item.items.length !== 0 ? (
                                                        item.items.map((item) => {
                                                            return (
                                                                <div
                                                                    className="screen-two__point-box"
                                                                    key={Math.random().toString(36).substr(2, 9)}
                                                                >
                                                                    <div className="screen-two__point"/>
                                                                    <p className="result__main-txt">{item}</p>
                                                                </div>
                                                            );
                                                        })
                                                    ) : false}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <button
                                    className="resutl__button"
                                    onClick={() => {
                                        api.getPaymentURL(orderId, promoCodeValue).then((res) => {
                                            if (res.ok) {
                                                setPrice(res.result.sum);
                                                setPaymentUrl(res.result.url);
                                                localStorage.setItem('paymenturl', res.result.url);
                                            }
                                        });
                                        setPopup(true);
                                    }}
                                >
                                    Получить детальный отчет
                                </button>
                            </>
                        )
                        }


                        <button
                            className="result__button-repeat"
                            onClick={() => {
                                navigate('/home');
                                // @ts-ignore
                                localStorage.clear('grade');
                                // @ts-ignore
                                localStorage.clear('active');
                                // @ts-ignore
                                localStorage.clear('gender');
                                localStorage.clear('gradetitle');
                                localStorage.clear('order_id');
                            }}
                        >
                            {' '}
                            Пройти проверку еще раз
                        </button>
                    </div>
                }
            </div>

            <Footer/>
            {popup ? (
                <div className="popup">
                    <div className="popup__content">
                        <div className="popup__close-box">
                            <button className="popup__close" onClick={() => setPopup(false)}/>
                        </div>
                        <p className="popup__header">Оплата</p>
                        <div className="popup__promo-box">
                            <p className="result__details popup__input-txt">Промокод</p>
                            <div className="popup__input-box">
                                <input
                                    onChange={handleChangePromoCodeValue}
                                    value={promoCodeValue ? promoCodeValue : ''}
                                    className="popup__input"
                                    type="text"
                                    placeholder="Введите промокод"
                                    disabled={promoCode}
                                    style={{borderColor: errorPromo ? "#EE6464" : "#dddddd"}}
                                />

                                {promoCode ? (
                                    <button
                                        className="popup__code-del"
                                        onClick={() => {
                                            // @ts-ignore
                                            setPromoCodeValue('');
                                            setPromoCode(false);
                                        }}
                                    >
                                        Сбросить
                                    </button>
                                ) : false}
                                <button
                                    className="popup__btn-code"
                                    onClick={() => {
                                        api.getPaymentURL(orderId, promoCodeValue).then((res) => {
                                            if (res.ok) {
                                                setPromoCode(true);
                                                setErrorPromo(false)
                                                setTotalPrice(res.result.sum);
                                                setPaymentUrl(res.result.url);
                                                localStorage.setItem('paymenturl', res.result.url);
                                            } else {
                                                setErrorPromo(true)
                                                setErrorPromoValue(res.error_code)
                                            }
                                        });
                                    }}
                                    style={{display: promoCode ? 'none' : 'block'}}
                                />
                            </div>
                            {errorPromo ?
                                <p className='popup__error'>{errorPromoValue === 'invalid_promo_code' ? "Неверный промокод" : errorPromoValue === 'promo_code_limit_expired' ? "Срок действия промокода истек" : errorPromoValue === 'promo_code_has_expired' ? "Промокод уже использован" : errorPromoValue === 'promo_code_has_not_started_yet' ? "промокод еще не запущен" : false}</p> : false
                            }
                        </div>
                        <div className="popup__info-box">
                            <p className="popup__info-txt">Наименование услуги</p>
                            <p className="popup__info-txt popup__info-txt_right">Стоимость</p>
                        </div>
                        <div className="popup__line"/>
                        <div className="popup__info-box">
                            <p className="popup__info-txt">Детальный отчет о состоянии иммунной системы</p>
                            <p className="popup__info-value">{price} {process.env.REACT_APP_TMP_CURRENCY}</p>
                        </div>
                        {promoCode ? (
                            <div className="popup__info-box">
                                <p className="popup__info-txt">
                                    Промокод: скидка {Math.round((1 - totalPrice / price) * 100)}%
                                </p>
                                <p className="popup__info-value">-{price - totalPrice} {process.env.REACT_APP_TMP_CURRENCY}</p>
                            </div>
                        ) : false}
                        <div className="popup__line"/>
                        <div className="popup__info-box">
                            <p className="popup__info-txt">Итог</p>
                            <p className="popup__info-value">{promoCode ? totalPrice : price} {process.env.REACT_APP_TMP_CURRENCY}</p>
                        </div>
                        <button
                            className="popup__btn-pay"
                            onClick={() => {
                                window.open(paymentUrl, '_self');
                                setPopup(false);
                                localStorage.clear('order_id');
                                localStorage.clear('gender');
                                localStorage.clear('active');
                                localStorage.clear('grade');
                                localStorage.clear('gradetitle');
                                localStorage.clear('paymenturl');
                            }}
                        >
                            Перейти к оплате
                        </button>
                    </div>
                </div>
            ) : false}
        </div>
    );
}
