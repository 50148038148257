import {useState} from 'react';
import AgreementKZ from "./AgreementKZ";
import AgreementRU from "./AgreementRU";
import PrivacyPolicyRU from "./PrivacyPolicyRU";

export default function Footer() {
    const [agreement, setAgreement] = useState(false);
    const [privacy_policy, setPrivacyPolicy] = useState(false);

    let agreement_content = process.env.REACT_APP_COUNTRY == 'KZ' ? AgreementKZ() : AgreementRU();
    let privacy_policy_content = process.env.REACT_APP_COUNTRY == 'KZ' ? '-' : PrivacyPolicyRU();
    let is_kz = process.env.REACT_APP_COUNTRY == 'KZ';


    return (
        <div>


            {is_kz ? (
                <footer className="footer">
                    <div className="header__logo footer__logo"/>
                    <p className="footer__info footer__info-one">{process.env.REACT_APP_FOOTER_ORGANIZATION}</p>
                    <p className="footer__info">{process.env.REACT_APP_FOOTER_ADDRESS}</p>
                    <a className="footer__inst" target="_blank"
                       href={process.env.REACT_APP_FOOTER_INSTAGRAMM_LNK}></a>
                    <a className="footer__pay-link" target="_blank" href="https://oncomed.kz/">Проект ТОО
                        "Научно-клинический центр онкореабилитации"</a><br/>
                    <a className="footer__pay-link" href="#" onClick={() => setAgreement(true)}>Условия
                        использования и оплаты</a>
                    <div className="footer__line"/>
                    <a className="footer__dev" href={process.env.REACT_APP_FOOTER_DEVELOPER_LNK} target="_blank">Разработчик
                        GeekChain</a>
                </footer>
            ) : (
                <footer className="footer">
                    <div className="header__logo footer__logo"/>
                    <div className="footer__info footer__info-one">
                        Принимаю условия <a className="footer__pay-link" href="#"
                                            onClick={() => setAgreement(true)}>оферты</a> и даю <a
                        className="footer__pay-link" href="#" onClick={() => setPrivacyPolicy(true)}>согласие</a> на
                        обработку
                        персональных данных
                    </div>
                    <div className="footer__line"/>
                    <a className="footer__dev" href={process.env.REACT_APP_FOOTER_DEVELOPER_LNK} target="_blank">Разработчик
                        GeekChain</a>
                </footer>
            )}


            {agreement ? (
                <div className="agreement__popup">
                    <div className="popup__content">
                        <div className="popup__close-box">
                            <button
                                className="popup__close"
                                onClick={() => {
                                    setAgreement(false);
                                }}
                            />
                        </div>
                        {agreement_content}
                    </div>
                </div>
            ) : false}
            {privacy_policy ? (
                <div className="agreement__popup">
                    <div className="popup__content">
                        <div className="popup__close-box">
                            <button
                                className="popup__close"
                                onClick={() => {
                                    setPrivacyPolicy(false);
                                }}
                            />
                        </div>
                        {privacy_policy_content}
                    </div>
                </div>
            ) : false}
        </div>
    );
}
