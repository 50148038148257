import React, { useRef } from 'react';
// @ts-ignore
export default function Start() {
	const form = useRef(null);
	// @ts-ignore
	const scrollEffect = (targetRef) => {
		targetRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	};

	return (
		<div>
			<div className="screen-one">
				<div>
					<h1 className="screen-one__header">Проверь свой иммунитет</h1>
					<p className="screen-one__text screen-one__text_dspn" style={{ maxWidth: '467px' }}>
						Технология определения состояния иммунитета по общему анализу крови
					</p>
					<div className="screen-one__point-box">
						<div className="screen-one__line">
							<div className="screen-one__ok" />
							<p className="screen-one__text">Рассчитаем индекс иммунитета</p>
						</div>
						<div className="screen-one__line">
							<div className="screen-one__ok" />
							<p className="screen-one__text">Выявим риски развития заболеваний</p>
						</div>
						<div className="screen-one__line">
							<div className="screen-one__ok" />
							<p className="screen-one__text">Предоставим направление для вас и вашего лечащего врача</p>
						</div>
					</div>
					<button className="screen-one__button" onClick={() => scrollEffect(form)}>
						Проверить иммунитет
					</button>
				</div>
				<div className="photo" />
			</div>
			<div className="screen-two-dsct">
				<div className="screen-two-dsct__number-box">
					<div className="screen-two-dsct__card">
						<p className="screen-two-dsct__card-title">3</p>
						<p className="screen-two-dsct__card-text">Авторских права</p>
					</div>
					<div className="screen-two-dsct__card">
						<p className="screen-two-dsct__card-title">15</p>
						<p className="screen-two-dsct__card-text">Лет исследований</p>
					</div>
					<div className="screen-two-dsct__card">
						<p className="screen-two-dsct__card-title">20 000+</p>
						<p className="screen-two-dsct__card-text">Анализов</p>
					</div>
				</div>
				<div className="screen-two-dsct__card-box">
					<div className="screen-two-dsct__card-info">
						<div className="screen-two-dsct__card-img screen-two-dsct__card-img1" />
						<p className="screen-two-dsct__card-info-title">Сдайте общий анализ крови</p>
						<p className="screen-two-dsct__card-info-text">
							Обратитесь в ближайшую поликлинику, лабораторию или медицинскую клинику. Результат анализа
							не должен превышать 2 недели.
						</p>
					</div>
					<div className="screen-two-dsct__card-info">
						<div className="screen-two-dsct__card-img screen-two-dsct__card-img2" />
						<p className="screen-two-dsct__card-info-title_link">
							Введите результаты анализа для рассчета состояния иммунитета
						</p>
						<button
							className="screen-one__button screen-one__button_card screen-one-dsct__btn"
							onClick={() => scrollEffect(form)}
						>
							Проверить иммунитет
						</button>
					</div>
					<div className="screen-two-dsct__card-info">
						<div className="screen-two-dsct__card-img screen-two-dsct__card-img3" />
						<p className="screen-two-dsct__card-info-title">Получите план обследования</p>
						<p className="screen-two-dsct__card-info-text">
							Детальный отчет содержит: список рекомендуемых анализов, риски развития заболеваний,
							рекомендации для дальнейшего обследования.
						</p>
					</div>
					<div className="screen-two-dsct__card-info">
						<div className="screen-two-dsct__card-img screen-two-dsct__card-img4" />
						<p className="screen-two-dsct__card-info-title">Контролируйте свой иммунитет</p>
						<p className="screen-two-dsct__card-info-text">
							Скоро у вас появится личный кабинет, где вы сможете: отслеживать динамику иммунитета,
							получить доступ к истории обследований, задать любой интересующий вопрос специалисту.
						</p>
					</div>
				</div>
			</div>
			<div className="screen-three-dsct">
				<p className="screen-three-dsct__header">Авторы технологии</p>
				<div className="screen-three-dsct__card-box">
					<div className="screen-three-dsct__card">
						<div className="screen-three-dsct__card-header">
							<div className="screen-three-dsct__photo screen-three-dsct__photo1" />
							<div className="screen-three-dsct__dsct-box">
								<p className="screen-three-dsct__title">Пономарев Игорь Олегович</p>
								<p className="screen-three-dsct__dsct">
									{' '}
									Профессор, к.м.н., врач онколог высшей категории
								</p>
							</div>
						</div>
						<div className="screen-three-dsct__main-box screen-three-dsct__main-box_one">
							<p className="screen-three-dsct__main-title">Профессиональный опыт:</p>
							<p className="screen-three-dsct__main-text">
								Стаж работы - более 40 лет. 2001 году - эксперт ВОЗ от Украины по проблеме рака молочной
								железы. На данный момент директор ЧК "Сenter of Systemic Medicine Ltd." (РК, г. Астана).
							</p>
						</div>
						<div className="screen-three-dsct__main-box">
							<p className="screen-three-dsct__main-title">Научная деятельность:</p>
							<p className="screen-three-dsct__main-text">
								В специальной литературе: 64 статьи, 2 монографии, 5 авторских свидетельств. Участник 5
								НИР, со-руководитель 2-х научных проектов АМН и НАН Украины и Казахстана.
							</p>
						</div>
					</div>
					<div className="screen-three-dsct__card">
						<div className="screen-three-dsct__card-header">
							<div className="screen-three-dsct__photo screen-three-dsct__photo2" />
							<div className="screen-three-dsct__dsct-box">
								<p className="screen-three-dsct__title">Удербаев Нурлыбек Нурланович</p>
								<p className="screen-three-dsct__dsct">
									{' '}
									Академик, доктор медицинских наук, врач онколог
								</p>
							</div>
						</div>
						<div className="screen-three-dsct__main-box screen-three-dsct__main-box_one">
							<p className="screen-three-dsct__main-title">Профессиональный опыт:</p>
							<p className="screen-three-dsct__main-text">
								Стаж работы - 26 лет. На данный момент генеральный директор "Научного-клинического
								центра инновационной медицины" (РК, г.Астана)
							</p>
						</div>
						<div className="screen-three-dsct__main-box">
							<p className="screen-three-dsct__main-title">Научная деятельность:</p>
							<p className="screen-three-dsct__main-text">
								82 научных работ, 8 патентов, 9 авторских свидетельств, 1 монография, руководитель 5
								социально-значимых проектов МЗ РК, руководитель 2 научно-технических проектов.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="screen-two">
				<div className="screen-two__start-box">
					<div className="screen-two__drop" />
					<div className="screen-two__box">
						<p className="screen-two__header">Сдайте общий анализ крови</p>
						<div className="screen-two__point-box">
							<div className="screen-two__point" />
							<p className="screen-two__point-text">
								Обратитесь в ближайшую поликлинику, лабораторию или медицинскую клинику.
							</p>
						</div>
						<div className="screen-two__point-box">
							<div className="screen-two__point" />
							<p className="screen-two__point-text">Результат анализа не должен превышать 2 недели</p>
						</div>
					</div>
				</div>
				<div className="line__one" />
				<div className="screen-two__start-box screen-two__start-box_right">
					<div className="screen-two__box screen-two__box_right">
						<p className="screen-two__header screen-two__header_right">
							Введите результаты анализа для рассчета состояния иммунитета
						</p>
						<button
							className="screen-one__button screen-one__button_free"
							// onClick={() => prop.scrollEffect(prop.form)}
							onClick={() => scrollEffect(form)}
						>
							Получить бесплатно
						</button>
					</div>
					<div className="screen-two__man" />
				</div>
				<div className="line__one line__one_mirror" />
				<div className="screen-two__start-box">
					<div className="screen-two__drop screen-two__recipe" />
					<div className="screen-two__box">
						<p className="screen-two__header">Получите направление на обследование</p>
						<div className="screen-two__point-box">
							<div className="screen-two__point" />
							<p className="screen-two__point-text">Список рекомендуемых анализов</p>
						</div>
						<div className="screen-two__point-box">
							<div className="screen-two__point" />
							<p className="screen-two__point-text">Риски развития заболеваний</p>
						</div>
						<div className="screen-two__point-box">
							<div className="screen-two__point" />
							<p className="screen-two__point-text">Рекомендации для дальнейшего обследования</p>
						</div>
					</div>
				</div>
				<div className="line__one" />
				<div className="screen-two__start-box screen-two__start-box_right">
					<div className="screen-two__box screen-two__box_right">
						<p className="screen-two__header screen-two__header_right screen-two__header_right_last">
							Контролируйте свой иммунитет
						</p>
						<p className="screen-two__point-text screen-two__point-text_info screen-two__point-text_info-right">
							Скоро у вас появится личный кабинет, где вы сможете:
						</p>
						<div className="screen-two__point-box screen-two__point-box_info">
							<div className="screen-two__point" />
							<p className="screen-two__point-text">Отслеживать динамику иммунитета</p>
						</div>
						<div className="screen-two__point-box screen-two__point-box_info">
							<div className="screen-two__point" />
							<p className="screen-two__point-text">Получить быстрый доступ к истории обследований</p>
						</div>
						<div className="screen-two__point-box screen-two__point-box_info">
							<div className="screen-two__point" />
							<p className="screen-two__point-text">Задать любой интересующий вопрос специалисту</p>
						</div>
					</div>
					<div className="screen-two__drop screen-two__drop_right screen-two__drop_right_last" />
				</div>
			</div>
			<div className="screen-three">
				<div className="screen-three__box">
					<p className="screen-three__number">3</p>
					<p className="screen-three__info">Авторских права</p>
				</div>
				<div className="screen-three__box">
					<p className="screen-three__number">15</p>
					<p className="screen-three__info">Лет исследований</p>
				</div>
				<div className="screen-three__box screen-three__box_last">
					<p className="screen-three__number">20 000+</p>
					<p className="screen-three__info">Анализов</p>
				</div>
			</div>
			<div className="screen-four">
				<p className="screen-four__headr">Авторы технологии</p>
				<div className="screen-four__flex">
					<div className="screen-four__card">
						<div className="screen-four__photo screen-four__photo_igor" />
						<div className="screen-four__line" />
						<p className="screen-four__name">Пономарев Игорь Олегович</p>
						<p className="screen-four__info">Профессор, к.м.н., врач онколог высшей категории</p>
					</div>
					<div className="screen-four__card">
						<div className="screen-four__photo screen-four__photo_nur" />
						<div className="screen-four__line" />
						<p className="screen-four__name">Удербаев Нурлыбек Нурланович</p>
						<p className="screen-four__info">Академик, доктор медицинских наук, врач онколог</p>
					</div>
				</div>
			</div>
			<div ref={form} />
		</div>
	);
}
