class Api {
	// @ts-ignore
	constructor({ baseUrl }) {
		// @ts-ignore
		this._baseUrl = baseUrl;
	}
	// @ts-ignore
	_request(path, method, params) {
		// @ts-ignore
		var url = this._baseUrl + path;

		return fetch(url, {
			method: method,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: new URLSearchParams(params).toString()
		})
			.then((res) => {
				return res.json()
			})
			// .catch((err) => {
			// 	console.log(err);
			// 	return err;
			// });
	}

	orderCreate(
		age: any,
		gender: any,
		hemoglobin: any,
		lymphocytes: any,
		erythrocytes: any,
		basophils: any,
		platelets: any,
		monocytes: any,
		leukocytes: any,
		eosinophils: any,
		soe: any,
		neutrophils: any,
		email: string
	) {
		return this._request('/api/v1/order/create', 'POST', {
			age: age,
			gender: gender,
			hemoglobin: hemoglobin,
			lymphocytes: lymphocytes,
			erythrocytes: erythrocytes,
			basophils: basophils,
			platelets: platelets,
			monocytes: monocytes,
			leukocytes: leukocytes,
			eosinophils: eosinophils,
			soe: soe,
			neutrophils: neutrophils,
			email: email
		}).then((res) => {
			return res;
		});
	}

  orderConfirm(order_id: string, code:any){
    return this._request("/api/v1/order/confirm", "POST" , {
      order_id: order_id,
      code: code
    })
  }
  getPaymentURL(order_id: string, promo_code:any){
    return this._request("/api/v1/order/getPaymentUrl", "POST" , {
      order_id: order_id,
      promo_code: promo_code
    })
  }
  getById(order_id: string){
    return this._request("/api/v1/order/getById", "POST" , {
      order_id: order_id,
    })
  }
	
}

const api = new Api({
	baseUrl: process.env.REACT_APP_BASE_URL
});

export default api;
